@mixin full-width {
  width: 100%;
}

.cancellation-main-topic {
  text-align: left;
  font-size: 32px;
  font-weight: 500;
}

.remove-choice-btn.MuiListItem-button:hover {
  background-color: #2d95f4;
  color: white;
}

.delete-choice-btn.MuiListItem-button:hover {
  background-color: #e83528;
  color: white;
}

.add-choice-btn-container {
  margin-top: 12px;
}

.custom-section-choices {
  margin-top: 12px;
}

.choice-item {
  .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
    padding-bottom: 0px;
  }
}

.input-field-text-area {
  .form-field {
    @include full-width;
  }
}

.MuiOutlinedInput-root.Mui-disabled {
  .MuiOutlinedInput-notchedOutline {
    border-color: #e5e5e5 !important;
  }
}

.MuiAutocomplete-inputRoot.Mui-disabled {
  background-color: #e5e5e5;
}

.choice-item-container {
  .page-layout .MuiPaper-root {
    padding-bottom: 0px;
  }
}

.choice-item-container {
  border: 1px solid #ccc;
}

.choice-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-left: 12px;
}

.choice-item:last-child {
  border-bottom: unset;
}

.add-choice-field {
  .MuiInputBase-root {
    height: 45.63px;
  }
}

.enable-switch {
  .MuiTypography-root {
    font-weight: 500;
    font-size: 18px;
    margin-right: 12px;
  }
}

.switch-status {
  font-size: 16px !important;
  color: #000000cc;
  font-weight: 500;
  white-space: nowrap;
}

.enable-switch {
  display: flex;
  align-items: center;
  max-width: 200px;
}

.switch-input-label {
  font-weight: 500;
  font-size: 18px;
  margin-right: 12px;
  white-space: nowrap;
}

.switch-input-container {
  margin-right: 12px;
}

.section-choice {
  margin-top: 16px;
  margin-bottom: 16px;
}

.field-set {
  border: 1px solid #cccccc;
  padding: 20px;
  border-radius: 8px;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.pl-2 {
  padding-left: 1rem !important;
}

.pl-50 {
  padding-left: 50px;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-16 {
  padding-top: 16px;
}

.pt-32 {
  padding-top: 32px;
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.align-item-center {
  align-items: center;
}

.header-topic-section-custom {
  padding-top: 16px;
  padding-bottom: 16px;
}

.topic {
  font-size: 24px;
  color: #222222;
}

.pointer {
  cursor: pointer;
}

.title {
  padding-top: 10px;
  color: #707070;
}

.icon {
  padding-left: 10px;
}

.icon svg {
  width: 16px;
  height: 16px;
}

.icon-plus {
  width: 16px;
  height: 16px;
  border: 1px solid #2d95f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2d95f4;
  font-size: 15px;
  margin-right: 5px;
}

.text-add-custom {
  color: #2d95f4;
  font-size: 16px;
}

.section-down-base {
  min-height: 100px;
}

.section-text-box-custom-text {
  font-size: 18px;
  font-weight: 500;
  color: #212529;
  margin-right: 10px;
}

.topic-display-custom-section {
  font-size: 16px;
  font-weight: 500;
  color: #000000cc;
}

.sub-topic-custom {
  font-size: 13px;
  color: #808080;
}

.title-and-switch-set-up-fee-custom {
  display: flex;
  align-items: center;
}

.section-switch {
  display: flex;
  align-items: center;
  padding-left: 50px;
}

.section-switch .form-field {
  margin: 0px;
}

.box-btn-add {
  padding-top: 16px;
  padding-bottom: 16px;
  width: 102px;
}

.btn-add-new-1 {
  text-align: center;
  width: 100%;
  border: 2px solid #1b4171;
  padding: 10px;
  border-radius: 5px;
  font-weight: 500;
  color: #1b4171;
  transition: width 0.5s;
}

.btn-add-new-1:hover {
  border: 2px solid #1b4171;
  background-color: #1b4171;
  color: #ffffff;
  width: 120%;
}

.card-rasons-section {
  position: relative;
  margin-top: 16px;
  padding: 25px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: 100%;
  min-height: 170px;
}

.section-compliance-details .form-field label {
  font-size: 18px !important;
}

.box-icon {
  display: flex;
  justify-content: end;
}

.field-display-type {
  padding: 16px;
}

.field-display-type .form-field {
  margin: 0px;
}

.field-display-type .form-field label {
  font-size: 14px !important;
}

.field-select-basic {
  width: 150px;
}

.field-select-basic .form-field {
  margin: 0px;
}

@media screen and(min-width: 375px) {
  .section-buttn-basic-fee {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-bottom: 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.text-switch {
  margin-left: 10px;
  font-size: 16px !important;
  color: #000000cc;
  font-weight: 500;
}

.fee-topic-font {
  font-size: 14px !important;
  color: #2d95f4;
  font-weight: 500;
  padding-right: 5px;
}

.fee-icon svg {
  margin-left: 2px;
  width: 100%;
  height: 100%;
}

.fee-icon {
  width: 17px;
  height: 17px;
}

.section-set-up-fees-custom {
  padding-bottom: 10px;
}

.section-time-fees-icon-delete {
  margin-left: 10px;
}

.input-time {
  width: 85px;
  height: 32px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  outline: none;
  padding-left: 20%;
  padding-right: 50%;
  font-size: 16px;
  font-weight: 500;
  color: #000000cc;
}

.hrs {
  font-weight: 500;
  color: #000000;
  border-right: none;
}
.min {
  font-weight: 500;
  color: #000000;
}

.input-hrs-custom {
  position: relative;
}

.input-min-custom {
  position: relative;
}

.text-position-custom {
  font-size: 14px;
  font-weight: 500;
  color: #8f9ba9;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 15px;
}

.text-validate {
  min-height: 20px;
  color: red;
}

.section-time-fees-set-fee-custom {
  position: relative;
  padding-left: 20px;
}

.custom-set-fee-by {
  padding-left: 65px;
}

@media screen and(min-width: 425px) {
  .section-main-fees {
    display: flex;
  }

  .w-100 {
    width: auto;
  }

  .pl-xs-50 {
    padding-left: 50px;
  }
}

@media screen and(min-width: 768px) {
  .pl-md-50 {
    padding-left: 50px;
  }
}

.create-card-custom {
  border-radius: 8px;
  border: 1px solid #cccccc;
  width: 100%;
  padding: 20px;
}

.custom-fast-field-section-reasons .form-field {
  margin-right: 0px;
}

.custom-array-component .MuiPaper-elevation1 {
  box-shadow: none;
  border: 1px solid #cccccc;
  border-radius: 0px;
}

.custom-array-component .MuiPaper-root {
  padding-bottom: 0px;
}

.collapse-custom-choices-section-close {
  overflow: hidden;
  max-height: 0;
  transition: max-height ease 0.4s;
  -moz-transition: max-height ease 0.4s;
  -ms-transition: max-height ease 0.4s;
  -o-transition: max-height ease 0.4s;
  -webkit-transition: max-height ease 0.4s;
}

.collapse-custom-choices-section-open {
  max-height: 500px;
  transition: max-height ease 0.4s;
  -moz-transition: max-height ease 0.4s;
  -ms-transition: max-height ease 0.4s;
  -o-transition: max-height ease 0.4s;
  -webkit-transition: max-height ease 0.4s;
}

.custom-btn-cancel-modal-1 {
  border: 2px solid #1b4171;
  border-radius: 4px;
  background-color: #1b4171;
  padding: 10px;
  width: 155px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  margin-left: 20px;
}

.input-field-text-area {
  // border: 1px solid;
  // padding: 7px 14px;
  // transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // border-color: #e0e0e0;
  // border-radius: 4px;
  // background-color: white;
  // width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.custom-btn-cancel-modal-2 {
  border: 2px solid #1b4171;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px;
  width: 155px;
  font-weight: 500;
  font-size: 15px;
  color: #1b4171;
}

.custom-btn-cancel-modal-2:hover {
  background-color: #1b4171;
  color: #ffffff;
}

.modal-topic {
  font-size: 18px;
  color: #1b4171;
  font-weight: 700;
}

.modal-content {
  font-size: 16px;
  color: #808080;
}

.text-center {
  text-align: center;
}

.section-custom-main-modal .MuiDialog-paperWidthSm {
  width: 400px;
}

.topic-success-modal {
  font-size: 18px;
  color: #1b4171;
  font-weight: 700;
  padding-left: 25px;
}

.up-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
}
